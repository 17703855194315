<template>
	<canvas :style="style"></canvas>
</template>

<script>
	export default {
		props: {
			count: {
				type: Number,
				default: 50
			}
		},
		data() {
			return {
				particles: [],
				max_speed: 0.5,
				color: '#fff',
				style: {
					display: 'block',
					width: '50%',
					height: '100vh',
					position: 'absolute',
					top: '0',
					right: '0',
					opacity: '.8',
					zIndex: '1'
				}
			}
		},
		watch: {
			count() {
				this.generate()
			}
		},
		mounted() {

			this.$ = this.$el.getContext('2d');

			this.generate();
			this.resize();

			window.addEventListener('resize', this.resize);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.resize);
		},
		methods: {

			w() { return this.$el.clientWidth },
			h() { return this.$el.clientHeight },

			resize() {
				this.$el.width = this.w();
				this.$el.height = this.h();

				this.loop();
			},

			generate() {
				while(this.particles.length < this.count)
					this.particles.push( new Particle(this) )

				while(this.particles.length > this.count)
						this.particles.shift()
			},

			draw() {
				this.$.clearRect(0, 0, this.w(), this.h());

				for (let i = 0, len = this.particles.length; i < len; i++)
					this.particles[i].update();
			},

			loop() {
				if(this.w() > 500)
					requestAnimationFrame(() => { this.loop() });

				this.draw();
			},

			hexToRGBA(hex, alpha = 1) {

				if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
					let c = hex.substring(1).split('');

					if(c.length === 3)
						c= [c[0], c[0], c[1], c[1], c[2], c[2]];

					c = '0x'+c.join('');

					return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+', '+alpha+')';
				}

				throw new Error('Bad Hex');
			},

			random(min, max, round) {

				let rand = Math.random() * (max - min + 1) + min;

				if (round === true) return Math.floor(rand);
				else return rand;
			}
		}
	}

	class Particle {
		constructor ($parent) {

			let velX = $parent.random(-$parent.max_speed, $parent.max_speed),
					velY = $parent.random(-$parent.max_speed, $parent.max_speed);

			this.$parent = $parent;
			this.color = $parent.color;
			this.x = $parent.random(0, $parent.w());
			this.y = $parent.random(0, $parent.h());
			this.velX = velX === 0 ? .1 : velX;
			this.velY = velY === 0 ? .2 : velY;
			this.radius = $parent.random(1, 3);
			this.active = true;
			this.id = $parent.particles.length === 0 ? 0 : $parent.particles[$parent.particles.length - 1].id + 1;

			this.opacity = .5;
			this.connect_radius = 150;
			this.connected = [];
		}

		update() {
			this.x += this.velX;
			this.y += this.velY;

			this.x = Math.round(0 + this.x);
			this.y = Math.round(0 + this.y);

			if(this.opacity < 1)
				this.opacity += 0.01;

			this.active = this.x > -1 && this.x < this.$parent.w() + 1 && this.y > -1 && this.y < this.$parent.h() + 1;

			if(this.active){
				this.draw();
				this.checkAround();
			}

			if (this.x <= 0 || this.x >= this.$parent.w()) this.velX = -this.velX;
			if (this.y <= 0 || this.y >= this.$parent.h()) this.velY = -this.velY;
		}

		draw() {
			this.$parent.$.beginPath();
			this.$parent.$.fillStyle = this.$parent.hexToRGBA(this.color, this.opacity);
			this.$parent.$.arc(this.x, this.y, this.radius, 0, 2 * Math.PI, false);
			this.$parent.$.fill();
			this.$parent.$.closePath();
		}

		checkAround() {
			this.connected = [];

			for (let i = 0, len = this.$parent.particles.length; i < len; i++) {
				let p = this.$parent.particles[i];
				let distance = this.distanceTo(p);
				distance = Math.floor((distance / this.connect_radius) * 100);

				if(!p.connected.includes(this.id) && p.active && p !== this && distance > 0 && distance < 100){
					this.connected.push(p.id);
					this.connect(p, distance*.01);
				}
			}
		}

		connect (p, alpha) {
			this.$parent.$.beginPath();
			this.$parent.$.strokeStyle = this.$parent.hexToRGBA(this.color, 1-alpha);
			this.$parent.$.moveTo(p.x, p.y);
			this.$parent.$.lineTo(this.x, this.y);
			this.$parent.$.stroke();
			this.$parent.$.closePath();
		}

		distanceTo(el) {
			let dx = el.x - this.x,
					dy = el.y - this.y;

			return Math.sqrt(dx * dx + dy * dy);
		}
	}
</script>
